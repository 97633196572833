import React,{ useCallback, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Modal, Typography } from "@mui/material";
import lodash from 'lodash'

import AddAssetModalHeaderAssetIcon from '../../icons/AddAssetModalHeaderUserIcon';
import ModalCloseIcon from '../../icons/ModalCloseIcon';
import AddAssetProgressActiveIcon from '../../icons/AddAssetProgressActiveIcon';
import AddAssetProgressInActiveIcon from '../../icons/AddAssetProgressInActiveIcon';
import AddAssetProgressCompletedIcon from '../../icons/AddAssetProgressCompletedIcon';
import SimpleSelectInput from '../../commonComponents/SimpleSelectInput';
import DollarInputIcon from '../../icons/DollarInputIcon';
import AddEntityIcon from '../../icons/AddEntityIcon';
import SearchAbleSingleSelector from '../../SearchAbleSingleSelector';
import TrashIcon from '../../icons/TrashIcon';
import CircleClearIcon from '../../icons/CircleClearIcon';
import { getOrganizationIdOFLoggedUser } from '../../../util/commonFunctions';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { hideAddAssetModal, createNewAsset, getAssetSummary, setNewEntitiesMaxCount } from '../../../redux/slices/assets/assetSummarySlice';
import { getNewAccessToken } from '../../../redux/slices/auth/authSlice';
import Loader from "../../commonComponents/Loader";

function AddAssetModal() {

    const dispatch = useDispatch();

    const sponserContainerRef = useRef(null);
    const entityContainerRef = useRef(null);
    const newEntityContainerRef = useRef(null);
    const newEntitiesListParentContainerRef = useRef(null);

    const newEntitiesListMaxCount = useSelector(state => state.assetSummary.newEntitiesListMaxCount)
    const addAssetModalShown = useSelector(state => state.assetSummary.addAssetModalShown)
    const sponsor_list = useSelector(state => state.assetSummary.sponsor_list)
    const asset_class_list = useSelector(state => state.assetSummary.asset_class_list)
    const sub_asset_class = useSelector(state => state.assetSummary.sub_asset_class)
    const sector = useSelector(state => state.assetSummary.sector)
    const industry_group = useSelector(state => state.assetSummary.industry_group)
    const investment_type = useSelector(state => state.assetSummary.investment_type)
    const investment_position = useSelector(state => state.assetSummary.investment_position)
    const region = useSelector(state => state.assetSummary.region)
    const state = useSelector(state => state.assetSummary.state)
    const entity = useSelector(state => state.assetSummary.entity)
    const user = useSelector(state => state.auth.user)
    const [Loading, setLoading] = useState(false)

    const [serverError, setServerError] = useState([])

    const closeAddAssetModal = () => {
        setServerError([])
        setLoading(false)
        dispatch(setNewEntitiesMaxCount(0))
        dispatch(hideAddAssetModal())
    }

    const formReadyToNext = (values) => {
        if(!values.name || !values.sponsor || !values.asset_class || !values.sub_asset_class || !values.sector || !values.industry_group
            || !values.investment_type || !values.investment_position || !values.region || !values.state ) {
            return false;
        }
        else {
            return true;
        }
    }

    const goBack = (values, setFieldValue) => {
        setFieldValue('progress', 1)
    }

    const formReadyToSubmit = (values) => {
        if (Loading) {
            return false
        }
        if(!values.entity || !values.invested || !values.commitment || !values.date) {
            return false;
        }

        return true;
    }

    const swicthToSelectInput = (setFieldValue, name) => {
        if(name === 'existing_entity_add_new_entity_clicked') {
            setFieldValue('entity', '')
        }
        else if(name === 'new_sponser_add_clicked') {
            setFieldValue('sponsor', '')
        }
        setFieldValue(name, false)
    }

    const clicked = (setFieldValue, name, id) => {
        setFieldValue(name, true)
        if(name === 'existing_entity_add_new_entity_clicked') {
            setFieldValue('entity', '')
        }
        else if(name === 'new_entity_add_new_entity_clicked_' + id) {
            setFieldValue('entity_' + id, '')
        }
        else if(name === 'new_sponser_add_clicked') {
            setFieldValue('sponsor', '')
        }
    }

    const addNewEntityButtonClicked = (setFieldValue, values) => {
        const updated_new_entities_list = [...values.new_entities_list, {id: newEntitiesListMaxCount, entity: '', invested: ''}];
        setFieldValue('new_entities_list', updated_new_entities_list);
        dispatch(setNewEntitiesMaxCount(newEntitiesListMaxCount + 1 ))
    }

    const getAssetEntities = (values) => {
        values.new_entities_list.forEach(entity => {
            if (newEntitiesListParentContainerRef && newEntitiesListParentContainerRef.current) {
                const child = newEntitiesListParentContainerRef.current.children[entity.id];
                if (child) {
                    const investedInputs = child.querySelectorAll('input[name^="invested_"]');
                    const entityInputs = child.querySelectorAll('input[name^="entity_"]');
                    const entitySelectorInputs = child.querySelectorAll('.select__single-value');

                    if(entityInputs.length > 0) {
                        Array.from(entityInputs).map((inputElement) => {
                            entity.entity = inputElement.value;
                        });
                    }
                    else if(entitySelectorInputs.length > 0) {
                        Array.from(entitySelectorInputs).map((inputElement) => {
                            entity.entity = inputElement.textContent;
                        });                   
                    }

                    Array.from(investedInputs).map((inputElement) => {
                        entity.invested = inputElement.value.replace(/,/g, '');
                    });
                }
            }
        });

        let entity_data = [...values.new_entities_list];
        entity_data = entity_data.map((entity) => {
            const { id, ...rest } = entity;
            return rest;
        });

        entity_data.unshift({ entity: values.entity, invested: values.invested.replace(/,/g, '')});

        return entity_data;
    }

    const prepareAssetFormParams = (values) => {
        let params = {
            name: values.name,
            investment: values.investment_and_non_investment === 'investment',
            sponsor: values.sponsor,
            asset_class: lodash.find(asset_class_list, function (asset_class) { return asset_class.value === values.asset_class }).label,
            sub_asset_class: lodash.find(sub_asset_class, function (sub_asset) { return sub_asset.value === values.sub_asset_class }).label,
            sector: lodash.find(sector, function (option) { return option.value === values.sector }).label,
            industry_group: lodash.find(industry_group, function (option) { return option.value === values.industry_group; }).label,
            investment_type: lodash.find(investment_type, function (option) { return option.value === values.investment_type; }).label,
            investment_position: lodash.find(investment_position, function (option) { return option.value === values.investment_position; }).label,
            region: lodash.find(region, function (option) { return option.value === values.region; }).label,
            state: lodash.find(state, function (option) { return option.value === values.state; }).label,
            date: values.date,
            commitment: values.commitment.replace(/,/g, ''),
            entity_data: getAssetEntities(values),
            organization: getOrganizationIdOFLoggedUser(user),
        }

        return params;
    }

    const submitAssetForm = (values, retry=0) => {
        if (Loading) {
            return false
        }
        let params = prepareAssetFormParams(values)
        setLoading(true)
        dispatch(createNewAsset(params))
        .then(({payload}) => {
            if(payload && payload.status === 201) {
                // setLoading(false)
                dispatch(getAssetSummary({ organization: getOrganizationIdOFLoggedUser(user) }))
                .then(({payload}) => {
                    if(payload && payload.status === 200) {
                        closeAddAssetModal()
                    }
                })
            }
            else if(payload && payload.response && payload.response.status === 400) {
                const errorArray = {};
                for( const key in payload.response.data ) {
                    if(Object.hasOwnProperty.call(payload.response.data, key)) {
                        const value = payload.response.data[key]
                        errorArray[key] = value.join('. ')
                    }
                }
                setLoading(false)
                setServerError([errorArray])
            }
            else if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                dispatch(getNewAccessToken())
                .then((res) => {
                    if(res.payload && res.payload.data && res.payload.data.success) {
                        return submitAssetForm(values, retry + 1)
                    }
                })
            }
        })
    }

    const removeComponentFromParent = (setFieldValue, values, id) => {
        const updatedChildren = values.new_entities_list.filter((entity) => entity.id !== id);
        setFieldValue('new_entities_list', updatedChildren);
    }
      
    const addNewEntity = (setFieldValue, values, id) => {
        return (
          <div ref={newEntityContainerRef} className='input-row'>
            <div className="selector-input-field-view">
              <div className="input-with-label">
                {values[`new_entity_add_new_entity_clicked_${id}`] ?    
                    <div className="input-field-view">
                        <div className="text-input-with-label">
                            <div className="row-input-with-icon-view">
                                <div className="input-content">
                                    <Field
                                        placeholder="Input entity name..."
                                        className="input-with-icon new-entity-add-new-entity"
                                        name={`entity_${id}`}
                                        value={values[`entity_${id}`]}
                                    />
                                    <div className='clear-button'>
                                        <CircleClearIcon callMethod={ () => swicthToSelectInput(setFieldValue, `new_entity_add_new_entity_clicked_${id}`) } />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  :
                  <div className="row-input-with-icon-view">
                    <div className="input-content">
                      <Field placeholder="Select entity" clicked={() => clicked(setFieldValue, `new_entity_add_new_entity_clicked_${id}`, id)} existingEntity={false} parentContainerRef={newEntityContainerRef} className="entity-input" options={entity} showAddButtonInMenu={true} showMenuItemsIcon={true} showSelectedControlIcon={true} inputName={'asset-modal'} component={SearchAbleSingleSelector} isSearchAble={true} name={`entity_id_${id}_2`} />
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="input-field-view invested-input">
              <div className="text-input-with-label">
                <div className="row-input-with-icon-view">
                  <div className="input-content">
                    <DollarInputIcon />
                    <Field className="input-with-icon" placeholder="0.00" onKeyPress={handleKeyPress} name={`invested_${id}`}
                        onChange={(e) => {
                            const newValue = e.target.value;

                            let rawValue = newValue.replace(/,/g, '');
                            const parsedValue = parseFloat(rawValue);
                            if (!isNaN(parsedValue)) {
                                const formattedValue = parsedValue.toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2,
                                });

                                setFieldValue(`invested_${id}`, formattedValue);
                            }
                            else
                            {
                                setFieldValue(`invested_${id}`, '');
                            }
                        }} 
                    />
                  </div>
                </div>
              </div>
            </div>
            <button type="button" onClick={() => removeComponentFromParent(setFieldValue, values, id) } className="remove-entity-button"><TrashIcon /></button>
          </div>
        );
    };

    const handleKeyPress = useCallback((event) => {
        const charCode = event.charCode;
        if (charCode !== 0 && charCode !== 8 && charCode !== 46 && (charCode < 48 || charCode > 57)) {
          event.preventDefault();
        }
    }, []);

    const DatePickerIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M17.5 8.33317H2.5M13.3333 1.6665V4.99984M6.66667 1.6665V4.99984M6.5 18.3332H13.5C14.9001 18.3332 15.6002 18.3332 16.135 18.0607C16.6054 17.821 16.9878 17.4386 17.2275 16.9681C17.5 16.4334 17.5 15.7333 17.5 14.3332V7.33317C17.5 5.93304 17.5 5.23297 17.2275 4.69819C16.9878 4.22779 16.6054 3.84534 16.135 3.60565C15.6002 3.33317 14.9001 3.33317 13.5 3.33317H6.5C5.09987 3.33317 4.3998 3.33317 3.86502 3.60565C3.39462 3.84534 3.01217 4.22779 2.77248 4.69819C2.5 5.23297 2.5 5.93304 2.5 7.33317V14.3332C2.5 15.7333 2.5 16.4334 2.77248 16.9681C3.01217 17.4386 3.39462 17.821 3.86502 18.0607C4.3998 18.3332 5.09987 18.3332 6.5 18.3332Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }

    const SingleDatePicker = (props) => {

        const handleDateRangeChange = (event, picker) => {
            if(picker.startDate) {
                props.setFieldValue('date', picker.startDate.format('YYYY-MM-DD'))
            }
        }

        const handleDateSelectedOnHide = (event, picker) => {
            props.setFieldValue('date', picker.startDate.format('YYYY-MM-DD'));
        }

        return (
            <DateRangePicker
                initialSettings={{
                  singleDatePicker: true,
                  showDropdowns: false,
                  startDate: new Date(),
                  maxDate: new Date(),
                  minYear: 1901
                }}
                onApply={handleDateRangeChange}
                onHide={handleDateSelectedOnHide}
              >
                <input  className="input-with-icon"  name="date" />
            </DateRangePicker>
        )
    }

    const getFormattedDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const investmentAndNonInvestmentHandleChange = (event, setFieldValue) => {
        setFieldValue('investment_and_non_investment', event.target.value)
        if(event.target.value == 'non-investment') {
            setFieldValue('asset_class', lodash.find(asset_class_list, function (asset_class) { return asset_class.label === 'Cash' }).value)
            setFieldValue('sub_asset_class', lodash.find(sub_asset_class, function (sub_asset) { return sub_asset.label === 'Cash' }).value)
            setFieldValue('sector', lodash.find(sector, function (single_sector) { return single_sector.label === 'Cash' }).value)
            setFieldValue('industry_group', lodash.find(industry_group, function (option) { return option.label === 'Cash' }).value)
            setFieldValue('investment_type', lodash.find(investment_type, function (option) { return option.label === 'Cash' }).value)
            setFieldValue('investment_position', lodash.find(investment_position, function (option) { return option.label === 'Cash' }).value)
        }
        else if(event.target.value == 'investment') {
            setFieldValue('asset_class', '')
            setFieldValue('sub_asset_class', '')
            setFieldValue('sector', '')
            setFieldValue('industry_group', '')
            setFieldValue('investment_type', '')
            setFieldValue('investment_position', '')
        }

    }

    return (
        <Modal
            open={addAssetModalShown}
            onClose={ closeAddAssetModal }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-asset-modal-view"
        >
            <div className="add-asset-modal" style={{height: '100%'}}>
                <div className="add-asset-modal-header">
                    <div className="add-asset-modal-header-content">
                        <div className="add-asset-modal-header-icons-view">
                            <div className="add-asset-modal-header-feature-icon">
                                <AddAssetModalHeaderAssetIcon />
                            </div>
                            <div className="add-asset-modal-header-close-button">
                                <ModalCloseIcon closeModal={ closeAddAssetModal } />
                            </div>
                        </div>
                        <div className="add-asset-modal-header-text">
                            <Typography className="add-asset-modal-header-title-text">Add asset</Typography>
                        </div>
                    </div>
                </div>
                <div className="add-asset-modal-content">
                    <Formik
                        initialValues={{
                            progress: 1,
                            name: '',
                            sponsor: '',
                            asset_class: '',
                            sub_asset_class: '',
                            sector: '',
                            industry_group: '',
                            investment_type: '',
                            investment_position: '',
                            region: '',
                            state: '',
                            date: getFormattedDate(),
                            commitment: '',
                            entity: '',
                            invested: '',
                            investment_and_non_investment: 'investment',
                            new_sponser_add_clicked: false,
                            new_entities_and_invested_id_state: false,
                            existing_entity_add_new_entity_clicked: false,
                            new_entity_add_new_entity_clicked: false,
                            new_entities_list: [],
                        }}
                        onSubmit={(values) => {
                            submitAssetForm(values)
                        }}
                    >
                        {({ errors, values, handleChange, handleSubmit, setFieldValue }) => {
                            return (

                                <>
                                    <div className="progress-step">
                                        <div className="progress-step-content">
                                            <div className='asset-details'>
                                                <div className='icon-view'>{values.progress === 2 ? <AddAssetProgressCompletedIcon /> : <AddAssetProgressActiveIcon />}</div>
                                                <div className='content'>
                                                    <Typography className="content-title-text">Asset Details</Typography>
                                                    <Typography className="content-info-text">Provide details about your asset</Typography>
                                                </div>
                                            </div>
                                            <div className='line-view'>
                                                <div className={'line ' + `${values.progress === 2 && 'active-line'}`}>
                                                </div>
                                            </div>
                                            <div className={'capital-invested ' + `${values.progress === 2 && 'capital-invested-active'}`}>
                                                <div className='icon-view'><AddAssetProgressInActiveIcon /></div>
                                                <div className='content'>
                                                    <Typography className="content-title-text">Capital Invested</Typography>
                                                    <Typography className="content-info-text">Enter amount invested by entity</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <>
                                        <Form className={"add-asset-form " + `${values.progress === 2 && 'add-capital-asset-form'}`}>
                                            {values.progress === 1 &&
                                                <>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Asset Name</Typography>
                                                            <Field className="asset-name-input" placeholder="What is your asset?" name="name" />
                                                        </div>
                                                    </div>
                                                    <div className="input-field-view">
                                                        <FormControl>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                defaultValue="investment"
                                                                name="investment_and_non_investment"
                                                                onChange={ (event) => investmentAndNonInvestmentHandleChange(event, setFieldValue)}
                                                            >
                                                                <FormControlLabel value="investment" control={<Radio
                                                                    checked={ values.investment_and_non_investment == 'investment' ? true : false }
                                                                    sx={{
                                                                        color: '#344054',
                                                                        '&.Mui-checked': {
                                                                            color: '#344054',
                                                                        },
                                                                        }}
                                                                        />} 
                                                                    label="Investment"
                                                                />
                                                                <FormControlLabel value="non-investment" control={<Radio
                                                                    checked={ values.investment_and_non_investment == 'non-investment' ? true : false }
                                                                    sx={{
                                                                        color: '#344054',
                                                                        '&.Mui-checked': {
                                                                            color: '#344054',
                                                                        },
                                                                        }}
                                                                    />}
                                                                label="Non-investment" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                    { values.new_sponser_add_clicked ?
                                                        <div className="input-field-view">
                                                            <div className="input-with-label">
                                                                <Typography className="input-label">Sponsor</Typography>
                                                                <div className="row-input-with-icon-view">
                                                                    <div className="input-content commitment-input">
                                                                        <Field className="input-with-icon" placeholder="Input sponsor name..." name="sponsor" />
                                                                        <div className='clear-button'>
                                                                            <CircleClearIcon callMethod={ () => swicthToSelectInput(setFieldValue, 'new_sponser_add_clicked') } />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="input-field-view">
                                                            <div className="input-with-label">
                                                                <Typography className="input-label">Sponsor</Typography>
                                                                <div className="row-input-with-icon-view">
                                                                    <div ref={entityContainerRef} className="input-content">
                                                                        <Field placeholder="Select sponsor" clicked={ () => clicked(setFieldValue, 'new_sponser_add_clicked') } addCustomSponser={true} parentContainerRef={entityContainerRef} className="sponsor-input" showAddButtonInMenu={true} showMenuItemsIcon={true} showSelectedControlIcon={true} inputName={'asset-modal'} component={SearchAbleSingleSelector} isSearchAble={false} selectedValue={sponsor_list && values.sponsor && lodash.find(sponsor_list, function (sponsor) { return sponsor.value === values.sponsor; })} options={sponsor_list} name="sponsor" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='input-row'>
                                                        <div className="input-field-view">
                                                            <div className="input-with-label">
                                                                <Typography className="input-label">Asset Class</Typography>
                                                                <Field placeholder="Select class" className="selector-input" component={SimpleSelectInput} isDisable={values.investment_and_non_investment == 'non-investment' ? true : false} isFormik={true} selectedValue={asset_class_list && values.asset_class && lodash.find(asset_class_list, function (asset_class) { return asset_class.value === values.asset_class; })} options={asset_class_list} name="asset_class" />
                                                            </div>
                                                        </div>
                                                        <div className="input-field-view">
                                                            <div className="input-with-label">
                                                                <Typography className="input-label">Sub-Asset Class</Typography>
                                                                <Field placeholder="Select sub-class" className="selector-input" component={SimpleSelectInput} isDisable={values.investment_and_non_investment == 'non-investment' ? true : false} isFormik={true} selectedValue={sub_asset_class && values.sub_asset_class && lodash.find(sub_asset_class, function (sub_asset) { return sub_asset.value === values.sub_asset_class; })} options={sub_asset_class} name="sub_asset_class" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='input-row'>
                                                        <div className="input-field-view">
                                                            <div className="input-with-label">
                                                                <Typography className="input-label">Sector</Typography>
                                                                <Field placeholder="Select sector" className="selector-input" component={SimpleSelectInput} isDisable={values.investment_and_non_investment == 'non-investment' ? true : false} isFormik={true} selectedValue={sector && values.sector && lodash.find(sector, function (option) { return option.value === values.sector; })} options={sector} name="sector" />
                                                            </div>
                                                        </div>
                                                        <div className="input-field-view">
                                                            <div className="input-with-label">
                                                                <Typography className="input-label">Industry Group</Typography>
                                                                <Field placeholder="Select industry group" className="selector-input" component={SimpleSelectInput} isDisable={values.investment_and_non_investment == 'non-investment' ? true : false} isFormik={true} selectedValue={industry_group && values.industry_group && lodash.find(industry_group, function (option) { return option.value === values.industry_group; })} options={industry_group} name="industry_group" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='input-row'>
                                                        <div className="input-field-view">
                                                            <div className="input-with-label">
                                                                <Typography className="input-label">Investment Type</Typography>
                                                                <Field placeholder="Select investment type" className="selector-input" component={SimpleSelectInput} isDisable={values.investment_and_non_investment == 'non-investment' ? true : false} isFormik={true} selectedValue={investment_type && values.investment_type && lodash.find(investment_type, function (option) { return option.value === values.investment_type; })} options={investment_type} name="investment_type" />
                                                            </div>
                                                        </div>
                                                        <div className="input-field-view">
                                                            <div className="input-with-label">
                                                                <Typography className="input-label">Investment Position</Typography>
                                                                <Field placeholder="Select investment position" className="selector-input" component={SimpleSelectInput} isDisable={values.investment_and_non_investment == 'non-investment' ? true : false} isFormik={true} selectedValue={investment_position && values.investment_position && lodash.find(investment_position, function (option) { return option.value === values.investment_position; })} options={investment_position} name="investment_position" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='input-row'>
                                                        <div className="input-field-view">
                                                            <div className="input-with-label">
                                                                <Typography className="input-label">Region</Typography>
                                                                <Field placeholder="Select region" className="selector-input" component={SimpleSelectInput} isFormik={true} selectedValue={region && values.region && lodash.find(region, function (option) { return option.value === values.region; })} options={region} name="region" />
                                                            </div>
                                                        </div>
                                                        <div className="input-field-view">
                                                            <div className="input-with-label">
                                                                <Typography className="input-label">State</Typography>
                                                                <Field placeholder="Select State" className="selector-input" component={SimpleSelectInput} isFormik={true} selectedValue={state && values.state && lodash.find(state, function (option) { return option.value === values.state; })} options={state} name="state" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="add-asset-modal-footer">
                                                        <div className="add-asset-modal-footer-content">
                                                            <div className="add-asset-modal-footer-buttons">
                                                                <button type="button" onClick={closeAddAssetModal} className="cancel-asset-button">Cancel</button>
                                                                <button type="button" onClick={ formReadyToNext(values) ? () => setFieldValue('progress', 2) : null} className={"disable-next-asset-button " + `${formReadyToNext(values) ? 'enable-next-asset-button' : ''}`}>Next</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                            {values.progress === 2 &&
                                                <>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Date</Typography>
                                                            <div className="row-input-with-icon-view">
                                                                <div className="input-content">
                                                                    <DatePickerIcon />
                                                                    <Field className="input-with-icon" setFieldValue={setFieldValue} component={ SingleDatePicker } name="date" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="input-field-view">
                                                        <div className="text-input-with-label">
                                                            <Typography className="input-label">Commitment</Typography>
                                                            <div className="row-input-with-icon-view">
                                                                <div className="input-content">
                                                                    <DollarInputIcon />
                                                                    <Field className="input-with-icon" placeholder="0.00" onKeyPress={handleKeyPress} name="commitment"
                                                                        onChange={(e) => {
                                                                            const newValue = e.target.value;

                                                                            let rawValue = newValue.replace(/,/g, '');
                                                                            const parsedValue = parseFloat(rawValue);
                                                                            if (!isNaN(parsedValue)) {
                                                                              const formattedValue = parsedValue.toLocaleString(undefined, {
                                                                                minimumFractionDigits: 0,
                                                                                maximumFractionDigits: 2,
                                                                              });

                                                                              setFieldValue('commitment', formattedValue);
                                                                            }
                                                                            else
                                                                            {
                                                                                setFieldValue('commitment', '');
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='input-row'>
                                                        <div className="selector-input-field-view">
                                                            <div className="input-with-label">
                                                                <Typography className="input-label">Entity</Typography>
                                                                { values.existing_entity_add_new_entity_clicked ?
                                                                    <div className="input-field-view">
                                                                        <div className="text-input-with-label">
                                                                            <div className="row-input-with-icon-view">
                                                                                <div className="input-content">
                                                                                    <Field placeholder="Input entity name..." className="input-with-icon existing-entity-add-new-entity" name="entity" />
                                                                                    <div className='clear-button'>
                                                                                        <CircleClearIcon callMethod={ () => swicthToSelectInput(setFieldValue, 'existing_entity_add_new_entity_clicked') }  />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="row-input-with-icon-view">
                                                                        <div ref={sponserContainerRef} className="input-content">
                                                                            <Field placeholder="Select entity" clicked={ () => clicked(setFieldValue, 'existing_entity_add_new_entity_clicked') } existingEntity={true} parentContainerRef={sponserContainerRef} className="entity-input" options={entity} showAddButtonInMenu={true} showMenuItemsIcon={true} showSelectedControlIcon={true} inputName={"asset-modal"} component={SearchAbleSingleSelector} isSearchAble={false} name="entity" />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="input-field-view">
                                                            <div className="text-input-with-label">
                                                                <Typography className="input-label">Capital Invested</Typography>
                                                                <div className="row-input-with-icon-view">
                                                                    <div className="input-content">
                                                                        <DollarInputIcon />
                                                                        <Field className="input-with-icon" placeholder="0.00" onKeyPress={handleKeyPress} name="invested"
                                                                            onChange={(e) => {
                                                                                const newValue = e.target.value;

                                                                                let rawValue = newValue.replace(/,/g, '');
                                                                                const parsedValue = parseFloat(rawValue);
                                                                                if (!isNaN(parsedValue)) {
                                                                                    const formattedValue = parsedValue.toLocaleString(undefined, {
                                                                                        minimumFractionDigits: 0,
                                                                                        maximumFractionDigits: 2,
                                                                                    });

                                                                                    setFieldValue('invested', formattedValue);
                                                                                }
                                                                                else
                                                                                {
                                                                                    setFieldValue('invested', '');
                                                                                }
                                                                            }} 
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='entity-container'>
                                                        <div ref={newEntitiesListParentContainerRef} className="new-entities-parent-container">
                                                            {values.new_entities_list.map((item, index) => (
                                                                <div key={item.id}>
                                                                    {addNewEntity(setFieldValue, values, item.id)}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <button type="button" className='add-entity-button-view' onClick={ () => addNewEntityButtonClicked(setFieldValue, values) }>
                                                        <AddEntityIcon />
                                                        <Typography className="add-entity-label">Add Entity</Typography>
                                                    </button>
                                                    <div className="add-asset-modal-footer">
                                                        <div className="add-asset-modal-footer-content">
                                                            {
                                                                Loading ? <Loader width="5%" height="5%"/> : null
                                                            }
                                                            { serverError && serverError.map((error, index) => (
                                                                error[lodash.keys(error)[0]] && <div className="error">{ error[lodash.keys(error)[0]] }</div>
                                                            ))}
                                                            <div className="add-asset-modal-footer-buttons">
                                                                <button type="button" onClick={() => goBack(values, setFieldValue) } className="back-asset-button">Back</button>
                                                                <button type="submit" className={"disable-add-asset-button " + `${formReadyToSubmit(values) ? 'enable-add-asset-button' : ''}`}>Add Asset</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                        </Form>
                                    </>
                                </>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </Modal>
    )
}

export default AddAssetModal