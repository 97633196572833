import React, {useCallback} from 'react'


function PortfolioStat(props) {
    
    const IncreaseStatIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 15.8333V4.16663M10 4.16663L4.16669 9.99996M10 4.16663L15.8334 9.99996" stroke="#12B76A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }

    const DecreaseStatIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 4.16663V15.8333M10 15.8333L15.8334 9.99996M10 15.8333L4.16669 9.99996" stroke="#F04438" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }


    const showFormattedValue = (value) => {
        if (props.irrType && props.show) {
            return getConvertedValue(value)
            // const formattedNumber = Math.abs(value);
            // let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1}) : '$' + value.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1});
            // return formattedValue + 'M'
        }
        switch (props.irrType) {
            case true:
                return value ? value.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1}) + ' %': '0%';
            case false:
                return getConvertedValue(value)
                // return value ? '$' + value.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1}) + 'M': '0M';
            default:
                return value ? '$' + value.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1}) + 'M': '0M';
          }
    }


    const getConvertedValue = useCallback((number, full_value=false) => {
        // return '$' + (number / 1000000).toFixed(1) + "M"
        let round_num = 1
        if (full_value === true) {
            round_num = 0
        }
        if (number >= 1000000000) {
            return '$' + (number / 1000000000).toFixed(round_num) + "B"
        }
        if (number >= 1000000) {
            return '$' + (number / 1000000).toFixed(round_num) + "M"
        } else if (number < 1000000) {
            if (number < 0) {
                number = Math.abs(number)
                return '-$' + (number / 1000).toFixed(round_num) + "K"
            }
            return '$' + (number / 1000).toFixed(round_num) + "K"
        }
    }, [])

    const showFormattedPercentage = (value) => {
        if(props.irrType) {
            return getConvertedValue(value)
        }
        return value ? value.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1}) + ' %': '0%';
    }

    const GetStatText = () => {
        if(props.irrType) {
            return (
                <span className='stat-value'>realized</span>
            )
        }
        else if(!props.irrType) {
            return (
                <span className='stat-value'>vs last quarter</span>
            )
        }
    }

    const ChangeInStats = () => {

        if(props && props.stat && props.stat.hasOwnProperty('increase') && props.stat.increase && !props.irrType) {
            return (
                <>
                    <div className='change-stat'>
                        <IncreaseStatIcon />
                        <span className='increase-stat-text'>{props && props.stat && showFormattedPercentage(props.stat.percentage)}</span>
                    </div>
                    <GetStatText />
                </>
            )
        }
        else if(props && props.stat && props.stat.hasOwnProperty('increase') && props.stat.increase && props.irrType) {
            return (
                <>
                    <div className='change-stat'>
                        <span className='increase-stat-text'>{props && props.stat && showFormattedPercentage(props.stat.percentage)}</span>
                    </div>
                    <GetStatText />
                </>
            )
        }
        else if(props && props.stat && props.stat.hasOwnProperty('increase') && !props.stat.increase && !props.irrType) {
            return (
                <>
                    <div className='change-stat'>
                        <DecreaseStatIcon />
                        <span className='decrease-stat-text'>{props && props.stat && showFormattedPercentage(props.stat.percentage)}</span>
                    </div>
                    <GetStatText />
                </>
            )
        }
        else if(props && props.stat && props.stat.hasOwnProperty('increase') && !props.stat.increase && props.irrType) {
            return (
                <>
                    <div className='change-stat'>
                        <span className='decrease-stat-text'>{props && props.stat && showFormattedPercentage(props.stat.percentage)}</span>
                    </div>
                    <GetStatText />
                </>
            )
        }

    }

    return (
        <div className='number-charts'>
            <div className='number-badge'>
                <span className='stat-value'>{props && props.stat && showFormattedValue(props.stat.value)}</span>
                <div className='change-stat-value'>
                    { props.show && <ChangeInStats /> }
                </div>

            </div>
        </div>
    )
}

export default PortfolioStat