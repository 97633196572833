import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import SelectSponserIcon from "./icons/SelectSponserIcon";
import SearchIcon from "./icons/SearchIcon";
import AddNewEntityInSelector from "./icons/AddNewEntityInSelector";
import SelectorTickIcon from "./icons/SelectorTickIcon";

const { MenuList, ValueContainer, SingleValue, Placeholder } = components;
const CustomMenuList = ({ selectProps, ...props }) => {
  const { onInputChange, inputValue, onMenuInputFocus } = selectProps;

  const ariaAttributes = {
    "aria-autocomplete": "list",
    "aria-label": selectProps["aria-label"],
    "aria-labelledby": selectProps["aria-labelledby"]
  };

  const addNewItem = () => {
    selectProps.clicked()
  }

  return (
    <div className="menu-container">
      <div className='custom-menu' style={{ padding: '8px', borderBottom: '1px solid var(--gray-300, #D0D5DD' }}>
        { props.isSearchAble &&
          <div className='searchable-input-view'>
            <SearchIcon />
            <input
              className='searchable-input'
              type="text"
              placeholder="Search"
              autoCorrect="off"
              autoComplete="off"
              spellCheck="false"
              value={inputValue}
              onChange={(e) =>
                onInputChange(e.currentTarget.value, {
                  action: "input-change"
                })
              }
              onMouseDown={(e) => {
                e.stopPropagation();
                e.target.focus();
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
                e.target.focus();
              }}
              onFocus={onMenuInputFocus}
              {...ariaAttributes}
            />
          </div>
        }
        { props.showAddButtonInMenu && <AddNewEntityInSelector clicked={ addNewItem } /> }
      </div>
      <MenuList {...props} selectProps={selectProps} />
    </div>
  );
};

const getMenuItemIconComponent = (props) => {
  if(props.inputName === 'asset-modal') {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: '8px' }}>
          {<SelectSponserIcon stroke={ props.isSelected ? '#FFF' : '#667085' } /> }
        </div>
        {props.label}
      </div>
    )
  }
  else if(props.inputName === 'asset-summary-detail-header') {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '1rem' }}>
        {props.label}
        <div>
          { props.isSelected && <SelectorTickIcon /> }
        </div>
      </div>
    )
  }
}

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
        { getMenuItemIconComponent(props) }
    </components.Option>
  );
};


const Control = ({ children, ...props }) => {
  return (
      <components.Control {...props}>
        { props.showSelectedControlIcon && <SelectSponserIcon isUserSelected={props.selectProps && props.selectProps.value && props.selectProps.value.hasOwnProperty('value') ? true : false} /> }{children}
      </components.Control>
    )
}

const CustomValueContainer = ({ children, selectProps, ...props }) => {

  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getClassNames: props.getClassNames,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps,
    theme: props.theme
  };

  return (
    <ValueContainer {...props} selectProps={selectProps}>
      {React.Children.map(children, (child) => {
        return child ? (
          child
        ) : props.hasValue ? (
          <SingleValue
            {...commonProps}
            isFocused={selectProps.isFocused}
            isDisabled={selectProps.isDisabled}
          >
            {selectProps.getOptionLabel(props.getValue()[0])}
          </SingleValue>
        ) : (
          <Placeholder
            {...commonProps}
            key="placeholder"
            isDisabled={selectProps.isDisabled}
            data={props.getValue()}
          >
            {props.placeholder}
          </Placeholder>
        );
      })}
    </ValueContainer>
  );
};

const SearchAbleSingleSelector = (props) => {

  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const onDomClick = (e) => {
    if (props.parentContainerRef && props.parentContainerRef.current) {
      let menu = props.parentContainerRef.current.querySelector(".select__menu");

      if (
        !props.parentContainerRef.current.contains(e.target) ||
        !menu ||
        !menu.contains(e.target)
      ) {
        setIsFocused(false);
        setInputValue("");
      }
    }
  };

  const onChange = (val) => {
    if(props.form) {
      props.form.setFieldValue(props.field.name, val.label);
      setIsFocused(false)
    }
    else {
      setIsFocused(false)
      props.onSelectInputChange(val)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  const getBackgroundColorColor = () => {
    if(props.inputName === 'asset-modal') {
      return 'black'
    }
    else if(props.inputName === 'asset-summary-detail-header') {
      return '#F9FAFB'
    }
  }

  const getSelectedTextColorColor = () => {
    if(props.inputName === 'asset-modal') {
      return 'white'
    }
    else if(props.inputName === 'asset-summary-detail-header') {
      return 'var(--gray-900, #101828)'
    }
  }

  const getTextWidth = (text, font) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const width = context.measureText(text).width;
    return width;
  }

  const selectWidth = props.selectedValue && props.selectedValue.label.length && props.adjustableWidth
  ? `${getTextWidth(props.selectedValue.label, '24px Inter') * 2 }px` // Adjust the multiplication factor as needed
  : "100%"; // Set a default width

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Inter',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '1.5rem',
      color: state.isSelected ? getSelectedTextColorColor() : 'var(--gray-900, #101828)',
      backgroundColor: state.isSelected ? getBackgroundColorColor() : 'white',
      paddingLeft: '0rem',
      paddingRight: '0rem',
      '&:hover': {
        backgroundColor: 'lightgray',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      width: selectWidth, // Set the dynamic width
    }),
  };

  return (
      <Select
        menuPosition="fixed"
        className={props.className}
        classNamePrefix="select"
        name="color"
        options={props.options}
        components={{
          MenuList: (menuListProps) => (
            <CustomMenuList {...menuListProps} showAddButtonInMenu={props.showAddButtonInMenu} showMenuItemsIcon={props.showMenuItemsIcon} isSearchAble={props.isSearchAble} showSelectedControlIcon={props.showSelectedControlIcon} />
          ),
          ValueContainer: CustomValueContainer,
          Control: (controlProps) => (
            <Control {...controlProps} showAddButtonInMenu={props.showAddButtonInMenu} showMenuItemsIcon={props.showMenuItemsIcon} showSelectedControlIcon={props.showSelectedControlIcon} />
          ),
          Option: (optionProps) => (
            <CustomOption {...optionProps} inputName={props.inputName} />
          ),
        }}
        value={props.selectedValue}
        inputValue={inputValue}
        isSearchable={false}
        onMenuInputFocus={() => setIsFocused(true)}
        onChange={ (val) => onChange(val) }
        onInputChange={ (val) => setInputValue(val)}
        placeholder={props.placeholder}
        existingEntity={props.existingEntity}
        clicked={props.clicked}
        styles={customStyles}
        {...{
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined
        }}
      />
  );
};

export default SearchAbleSingleSelector;

