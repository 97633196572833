import React from 'react';
import { useDispatch } from 'react-redux';

import SearchBar from '../../../../components/commonComponents/SearchBar';
import OrganizationFilterIcon from '../../../../components/icons/FilterButtonIcon';
import AddOrganizationIcon from '../../../../components/icons/AddOrganizationIcon';

import { showAddOrganizationModal } from '../../../../redux/slices/organization/organizationSlice';

export default function OrganizationHeaderContent() {

  const dispatch = useDispatch()

  const openAddOrganizationModal = () => {
    dispatch(showAddOrganizationModal())
  }

  return (
    <div className="organization-header-content">
      <span className="manage-organization-text">Manage Organizations</span>
      <SearchBar />
      <div className="organization-table-filters">
        {/*<button className="filters-button"><OrganizationFilterIcon />Filters</button>*/}
        <button className="add-organization-button" onClick={ openAddOrganizationModal }><AddOrganizationIcon />Add Organization</button>
      </div>
    </div>
  )
}